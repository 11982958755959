import firebase from 'firebase/app'
import Model from './Model'

export interface School extends Model {
  name?: string
  address?: string
  phoneNumber: Array<string>
  email: Array<string>
  studentSMSCount: number
  schoolSMSCount: number
  supervisorName: string
  supervisorEmail: string
  slogan?: string
  schoolCode: string
  country: string
  city: string
  logoRef?: string
  location?: firebase.firestore.GeoPoint
  chairman?: string
  chairmanPhones: string[]
  creationDate: number

  token: string | null
  letextoId: string | null
}

export const collectionName = 'schools'
export const schoolLogoCollectionName = 'logos'
