import {
  PermissionMap,
  // PermissionMapSaved as PermissionMapSavedType,
  PERMISSIONS_FETCHED,
  // PERMISSIONS_MAP_SAVED,
  PermissionsFetched as PermissionsFetchedType,
} from './types'
import { Permission } from '../../../models/Permission'

export function permissionsFetched(
  permissions: Permission,
  permissionsMap: PermissionMap,
): PermissionsFetchedType {
  return {
    type: PERMISSIONS_FETCHED,
    payload: { permissions, permissionsMap },
  }
}
