import { Classroom } from '../../../models/Classroom'

export const CLASSROOMS_FETCHED = 'classrooms/ClassroomsFetched'
export const CLASSROOM_SELECTED = 'classrooms/classroomSelected'

export type ClassroomState = {
  classrooms: Classroom[]
  selectedClassroom: Classroom | null
}

export type ClassroomsFetched = {
  type: typeof CLASSROOMS_FETCHED
  payload: Classroom[]
}

export type ClassroomSelected = {
  type: typeof CLASSROOM_SELECTED
  classroom: Classroom | null
}

export type ClassroomsActionsType = ClassroomsFetched | ClassroomSelected
