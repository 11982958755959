import {
  ADD_MESSAGE,
  CLEAR_ALL_MESSAGES,
  CLEAR_MESSAGE,
  AlertActionsTypes,
  AppMessage,
} from './types'
import { Dispatch } from 'redux'
import Bugsnag from '@bugsnag/js'

export const addMessage = (
  message: Omit<AppMessage, 'uuid'>,
): AlertActionsTypes => {
  // Notify error monitoring backend if error
  if (message.error) Bugsnag.notify(message.error)

  return {
    type: ADD_MESSAGE,
    message,
  }
}

export const clearMessage = (uuid: string): AlertActionsTypes => ({
  type: CLEAR_MESSAGE,
  uuid,
})

export const clearAllMessages = (): AlertActionsTypes => ({
  type: CLEAR_ALL_MESSAGES,
})

export function flashMessage(message: Omit<AppMessage, 'uuid'>) {
  return (dispatch: Dispatch) => dispatch(addMessage(message))
}
