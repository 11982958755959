import Model from './Model'

export type AvatarColor = {
  r: number
  g: number
  b: number
}

export interface User extends Model {
  uid: string
  profileId: string
  schoolIds: Array<string>
  type: 'AROLI' | 'SCHOOL'
  phoneNumber: string
  displayName: string
  email: string
  hasChangedPassword?: boolean
  photoURL: string
  avatarColors?: AvatarColor
  avatarRef?: string
  isFirstTime?: boolean
}

export const collectionName = 'users'
