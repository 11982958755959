import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  AuthActionTypes,
  Credentials,
  User,
  AUTH_STATE_UPDATED,
  LOADING,
  LOGOUT_SUCCESS,
  AuthMode,
  MODE_CHANGED,
  PASSWORD_RESET_EMAIL_SENT,
  PASSWORD_RESET_EMAIL_FAILED,
  LOGOUT,
} from './types'
import { Dispatch } from 'redux'

import { auth } from '../../../services'
import { User as UserData } from '../../../models/User'
import { addMessage } from '.././alerts/actions'
import UserDAO from '../../../dao/UserDAO'
import { retrieveSchools, globalSchoolSelected } from '../schools/actions'
import { Profile } from '../../../models/Profile'
import ProfileDAO from '../../../dao/ProfileDAO'
import { schoolProfilesRetrieved } from '../profiles/actions'
import firebase from 'firebase/app'

const dao = new UserDAO()
const profileDao = new ProfileDAO()

export const loading = (): AuthActionTypes => ({
  type: LOADING,
})

export const loginSuccess = (
  user: User,
  userData: UserData,
  profile: Profile,
  authPersistance: firebase.auth.Auth.Persistence,
): AuthActionTypes => {
  return {
    type: LOGIN_SUCCESS,
    user,
    userData,
    profile,
    authPersistance,
  }
}

export const loginFailed = (): AuthActionTypes => {
  return {
    type: LOGIN_FAILED,
  }
}

export const logoutSuccess = (): AuthActionTypes => ({
  type: LOGOUT_SUCCESS,
})

export const setAuthMode = (mode: AuthMode): AuthActionTypes => ({
  type: MODE_CHANGED,
  mode,
})

export const passwordResetEmailSent = (): AuthActionTypes => ({
  type: PASSWORD_RESET_EMAIL_SENT,
})

export const passwordResetEmailFailed = (): AuthActionTypes => ({
  type: PASSWORD_RESET_EMAIL_FAILED,
})

/**
 *
 * @param credentials
 * @param authPersistance
 */
export const login = (
  credentials: Credentials,
  authPersistance: firebase.auth.Auth.Persistence = firebase.auth.Auth
    .Persistence.SESSION,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(loading())
    try {
      await firebase.auth().setPersistence(authPersistance)

      // Set auth persistance
      const user = await auth.signInWithEmailAndPassword(
        credentials.email,
        credentials.password,
      )

      // Check if user can access the dashboard
      const userData = await dao.getById(user.user?.uid as string)
      if (userData.type !== 'SCHOOL') {
        await auth.signOut()
        dispatch(logoutSuccess())
        dispatch(loginFailed())
        dispatch(
          addMessage({
            level: 'error',
            shortMessage: `Vous n'avez pas le droit d'accéder à cette interface`,
            error: new Error(
              `Vous n'avez pas le droit d'accéder à cette interface`,
            ),
          }),
        )
      } else {
        // const idToken = await user.user?.getIdToken()
        // console.info(idToken)

        const profile = await profileDao.getById(userData.profileId)

        dispatch(
          loginSuccess(user.user as User, userData, profile, authPersistance),
        )

        // If user is a "Superviseur", we retrieve his schools
        // Otherwise it's some user from a school so we just set the globalSchoolId
        if (profile.name === 'Superviseur' && !profile.schoolId) {
          retrieveSchools()
        } else {
          dispatch(globalSchoolSelected(userData.schoolIds[0]))
          const schoolProfiles = await profileDao.getBySchoolId(
            userData.schoolIds[0],
          )
          dispatch(schoolProfilesRetrieved(schoolProfiles))
        }
      }
    } catch (e) {
      dispatch(loginFailed())
      dispatch(
        addMessage({
          level: 'error',
          shortMessage: e.message,
          error: e,
        }),
      )
    }
  }
}

export const logout = () => {
  return async (dispatch: Dispatch) => {
    try {
      await auth.signOut()
      dispatch(logoutSuccess())
      dispatch({ type: LOGOUT })
    } catch (e) {
      dispatch(
        addMessage({ level: 'error', shortMessage: e.message, error: e }),
      )
    }
  }
}

export const resetPassword = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loading())
      await auth.sendPasswordResetEmail(email)
      dispatch(passwordResetEmailSent())
      dispatch(
        addMessage({
          level: 'success',
          shortMessage:
            "Un mail contenant un lien de reinitialisation a ete envoye a l'adresse e-mail specifiee.",
        }),
      )
    } catch (e) {
      dispatch(passwordResetEmailFailed())
      dispatch(
        addMessage({ level: 'error', shortMessage: e.message, error: e }),
      )
    }
  }
}

export const authStateUpdated = (
  user: User,
  userData: UserData,
  profile: Profile,
): AuthActionTypes => ({
  type: AUTH_STATE_UPDATED,
  user,
  userData,
  profile,
})
