import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { HomeSharp } from '@material-ui/icons'
import React from 'react'
import { useHistory } from 'react-router-dom'
import useUtilitiesStyles from '../../../theme/useUtilitiesStyles'

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      height: '100vh',
      width: '100vw',
      backgroundColor: palette.primary.light,
      color: palette.common.white,
    },
  }),
)

const NotFoundErrorPage: React.FC = () => {
  const styles = useStyles()
  const { my2 } = useUtilitiesStyles()
  const history = useHistory()

  const handleBackToHome = (): void => {
    history.push('/')
  }

  return (
    <Grid
      container
      alignItems='center'
      justify='center'
      className={styles.root}
      direction='column'>
      <img src='/images/not-found.png' alt='404 error' className={my2} />

      <Typography variant='h5' className={my2}>
        Oups!... La page que vous cherchez n&apos;existe pas.
      </Typography>

      <IconButton
        size='medium'
        color='inherit'
        title="Retour à l'accueil"
        onClick={handleBackToHome}>
        <HomeSharp fontSize='large' />
      </IconButton>
    </Grid>
  )
}

export default NotFoundErrorPage
