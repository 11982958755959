import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import AlertItem from './AlertItem'
import { AppState } from '../../store/rootReducer'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: AppState) => ({
  messageIds: state.alerts.messageIds,
})

const mapDispatch = {}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {}

const Alerts: React.FC<Props> = (props) => {
  const { messageIds } = props

  return (
    <div>
      {messageIds?.map((id, index) => (
        <AlertItem uuid={id} key={index} />
      ))}
    </div>
  )
}

export default connector(Alerts)
