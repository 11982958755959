import { School } from '../../../models/School'

export const SCHOOL_RETRIEVED = 'school/schoolRetrieved'
export const SCHOOL_SELECTED = 'school/schoolSelected'
export const GLOBAL_SCHOOL_SELECTED = 'school/globalSchoolSelected'

export type SchoolState = {
  schools: School[]
  selectedSchoolId: string | null
  selectGlobalSchoolId: string | null
}

interface SchoolRetrieved {
  type: typeof SCHOOL_RETRIEVED
  schools: School[]
}

interface SchoolSelected {
  type: typeof SCHOOL_SELECTED
  selectedSchoolId: string | null
}

interface GlobalSchoolSelected {
  type: typeof GLOBAL_SCHOOL_SELECTED
  selectedSchoolId: string | null
}

export type SchoolActionTypes =
  | SchoolRetrieved
  | SchoolSelected
  | GlobalSchoolSelected
