import {
  CLASSROOMS_FETCHED,
  ClassroomsActionsType,
  ClassroomState,
  CLASSROOM_SELECTED,
} from './types'
import produce from 'immer'

const initialState: ClassroomState = {
  classrooms: [],
  selectedClassroom: null,
}

export default (
  state = initialState,
  action: ClassroomsActionsType,
): ClassroomState => {
  switch (action.type) {
    case CLASSROOMS_FETCHED:
      return produce(state, (draft) => {
        draft.classrooms = action.payload
      })
    case CLASSROOM_SELECTED:
      return produce(state, (draft) => {
        draft.selectedClassroom = action.classroom
      })

    default:
      return state
  }
}
