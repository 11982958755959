import {
  USER_SELECTED,
  UsersActionsTypes,
  UserState,
  SELECTION_CLEARED,
} from './types'
import produce from 'immer'

const initialState: UserState = {
  selectedUsersId: null,
  mode: null,
}

export default (
  state: UserState = initialState,
  action: UsersActionsTypes,
): UserState => {
  switch (action.type) {
    case USER_SELECTED:
      return produce(state, (draft) => {
        draft.selectedUsersId = action.selectedUsersId
        draft.mode = action.mode
      })
    case SELECTION_CLEARED:
      return produce(state, (draft) => {
        draft.selectedUsersId = null
        draft.mode = null
      })

    default:
      return state
  }
}
