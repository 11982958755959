export const DRAWER_TOGGLED = 'layout/drawerToggled'
export const ACCOUNT_FORM_TOGGLED = 'layout/accountFormToggled'
export const STUDENT_TAB_CHANGED = 'layout/studentTabChanged'
export const SETTINGS_CURRENT_TAB_CHANGED = 'layout/settingsCurrentTabChanged'
export const CONFIG_CURRENT_TAB_CHANGED = 'layout/configCurrentTabChanged'
export const CLASSROOM_SELECTOR_NODE_TOGGLED =
  'layout/classroomSelectorNodeToggled'
export const SCHOOLING_PAGE_CURRENT_TAB_CHANGED =
  'layout/schoolingPageCurrentTab'

export type LayoutState = {
  isDrawerOpen: boolean
  userAccountFormOpen: boolean
  studentCurrentTab: number
  settingsCurrentTab: number
  configCurrentTab: number
  classroomSelectorToggledNodes: string[]
  schoolingPageCurrentTab: number
}

interface SchoolingPageTabChanged {
  type: typeof SCHOOLING_PAGE_CURRENT_TAB_CHANGED
  index: number
}

interface DrawerToggled {
  type: typeof DRAWER_TOGGLED
}

interface AccountFormToggled {
  type: typeof ACCOUNT_FORM_TOGGLED
}

interface StudentTabChanged {
  type: typeof STUDENT_TAB_CHANGED
  index: number
}

interface SettingsCurrentTabChanged {
  type: typeof SETTINGS_CURRENT_TAB_CHANGED
  index: number
}

interface ConfigCurrentTabChanged {
  type: typeof CONFIG_CURRENT_TAB_CHANGED
  index: number
}

interface ClassroomSelectorNodeToggled {
  type: typeof CLASSROOM_SELECTOR_NODE_TOGGLED
  nodeIds: string[]
}

export type LayoutActionTypes =
  | DrawerToggled
  | AccountFormToggled
  | StudentTabChanged
  | SettingsCurrentTabChanged
  | ConfigCurrentTabChanged
  | ClassroomSelectorNodeToggled
  | SchoolingPageTabChanged
