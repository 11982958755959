import { AvatarColor } from '../models/User'
import moment from 'moment'
import firebase from 'firebase'
import { Moment } from 'moment'

function updateObject<T = {}>(oldObject: T, newValue: Partial<T>): T {
  return Object.assign({}, oldObject, newValue)
}

function updateItemInArray<T extends Partial<{ id: string | number }>>(
  items: T[],
  itemId: string | number,
  callback: (item: T) => T,
): T[] {
  const updatedItems = items.map((item: T) => {
    if (item.id !== itemId) return item

    const updatedItem = callback(item)
    return updatedItem
  })

  return updatedItems
}

export { updateObject, updateItemInArray }

export function randString(): string {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}

export function getInitials(value: string): string {
  if (!value) return ''
  return value.split(' ').reduce((accumulator, current) => {
    if (accumulator.trim().length === 2) {
      return accumulator
    }
    return `${accumulator}${current[0]?.trim() ? current[0] : ''}`
  }, '')
}

/**
 * Get random integer between *min* and *max*
 *
 * @export
 * @param {number} [min=1]
 * @param {number} [max=10000]
 * @returns {number}
 */
export function randomInt(min = 1, max = 10000): number {
  return min + Math.random() * (max - min)
}

/**
 * Get random background color in *rgba* format
 *
 * @export
 * @returns {{ backgroundColor: string }}
 */
export function randomBackgroundColor(): { backgroundColor: string } {
  return {
    backgroundColor: `rgba(${randomInt(0, 255)}, ${randomInt(
      0,
      255,
    )}, ${randomInt(0, 255)})`,
  }
}

/**
 *GEt random avatar colors
 *
 * @export
 * @returns {AvatarColor}
 */
export function randomAvatarColor(): AvatarColor {
  return {
    r: Math.round(randomInt(0, 255)),
    g: Math.round(randomInt(0, 255)),
    b: Math.round(randomInt(0, 255)),
  }
}

/**
 * Get formatted data from {firebase.firestore.FieldValue} timestamp
 *
 * @export
 * @param {firebase.firestore.FieldValue} fieldValue
 * @param {string} format - Date format pattern
 * @returns {string}
 */
export function getDateFromFieldValue(
  fieldValue: firebase.firestore.FieldValue,
  format = 'DD-MM-YYYY',
): string {
  if (!fieldValue) {
    return 'Il y a un moment'
  }

  return moment(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fieldValue as Record<string, any>).toDate(),
  ).format(format)
}

/**
 * Get formatted data from {firebase.firestore.FieldValue} timestamp
 *
 * @export
 * @param {firebase.firestore.FieldValue} fieldValue
 * @param {string} format - Date format pattern
 * @returns {string}
 */
 export function getDateFromFieldValueDay(
  fieldValue: firebase.firestore.FieldValue,
  format = 'DD-MM-YYYY HH:mm:ss',
): string {
  if (!fieldValue) {
    return 'Il y a un moment'
  }

  return moment(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fieldValue as Record<string, any>).toDate(),
  ).format(format)
}

/**
 * Get formatted data from {firebase.firestore.FieldValue} timestamp
 *
 * @export
 * @param {firebase.firestore.FieldValue} fieldValue
 * @param {string} format - Date format pattern
 * @returns {string}
 */
 export function getDateMonth(
  fieldValue: firebase.firestore.FieldValue,
  format = 'M',
): string {
  if (!fieldValue) {
    return 'Il y a un moment'
  }
  
  return moment(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fieldValue as Record<string, any>).toDate(),
  ).format(format)
}

export function serverTimeStamp(): firebase.firestore.FieldValue {
  return firebase.firestore.FieldValue.serverTimestamp()
}

/**
 * Get colors in a string format
 *
 * @param colors
 */
export function stringifyColors(colors: AvatarColor | undefined): string {
  if (!colors) return 'rgba(255,255,255,1)'
  return `rgba(${colors.r},${colors.g},${colors.b}, 1)`
}

/**
 * Return timestamp from date or Moment object
 *
 * @param {any} date
 * @returns {number} - Timestamp
 */
export const getTimestamp = (date: any): number => {
  if (typeof date === 'number') return date

  try {
    return date.toDate().getTime()
  } catch (e) {
    return date.getTime()
  }
}

/**
 * Turn a regular string to a slug
 *
 * @param {*} str
 * @returns
 */
export function slugify(str: string): string {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuunc------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

export function today(format = 'DD/MM/YYYY'): string {
  return moment().format(format)
}

export function moneyFormat(amount: number, currency = 'FCFA'): string {
  return amount.toLocaleString() + ' ' + currency
}

export function moneyFormatVerify(amount: number, currency = 'FCFA'): string {
  if(amount===undefined){
    return ''
  }
  return amount.toLocaleString() + ' ' + currency
}

export function percentageFormat(amount: number, label = '%'): string {
  return amount.toFixed(2) + ' ' + label
}

export function operationDateFormat(date: Moment): Date {
  return new Date(date.format('YYYY-MM-DD 00:00:00'))
}

export function dateFormatted(date: Date): string {
  return moment(date as Date).format('DD-MM-YYYY')
}

export function timestampToDateFormat(
  fieldValue: any,
  format = 'DD-MM-YYYY',
): string {
  if (!fieldValue) {
    return 'Il y a un moment'
  }

  let date

  try {
    date = (fieldValue as Record<string, any>).toDate()
  } catch (e) {
    date = fieldValue as Date
  }

  return moment(date).format(format)
}

export function timestampToRelativeDateFormat(fieldValue: any): string {
  if (!fieldValue) {
    return 'Il y a un moment'
  }

  let date

  try {
    date = (fieldValue as Record<string, any>).toDate()
  } catch (e) {
    date = fieldValue as Date
  }

  const elapsedDays = moment(date).diff(new Date(), 'days')

  return elapsedDays < 1
    ? moment(date).fromNow()
    : moment(date).format('DD-MM-YYYY')
}

/**
 * Round a number to given decimal count
 *
 * @export
 * @param {number} value - value to round
 * @param {number} [decimal=0] - number of decimal
 * @returns
 */
export function round(value: number, decimal = 0): number {
  const factor = Math.pow(10, decimal)

  return Math.round(value * factor) / factor
}

/**
 * Normalize grade to *max*
 *
 * @param {number} grade
 * @param {number} total
 * @param {number} [max=10]
 * @returns {number}
 */
export function normalizeGrade(grade: number, total: number, max = 10): number {
  return round((grade / total) * max, 2)
}

/**
 * Returns formatted date according to ```mode```
 *
 * @export
 * @param {(number | Date)} date
 * @param {('full' | 'short')} mode
 * @returns {string}
 */
export function formatDate(
  date: number | Date,
  mode: 'full' | 'short' = 'short',
): string {
  const _date = new Date(date)

  if (mode === 'full') {
    return _date.toLocaleDateString(undefined, {
      day: '2-digit',
      weekday: 'long',
      month: 'long',
      year: 'numeric',
    })
  } else {
    return _date.toLocaleDateString()
  }
}

/**
 * Get ```count``` words from text
 *
 * An ```ellipsis``` is added if ```count``` is lower than ```text``` words count
 *
 * @export
 * @param {string} text
 * @param {number} [count=0]
 * @param {string} [ellipsis='...']
 * @returns {string}
 */
export function words(text: string, count = 0, ellipsis = '...'): string {
  if (!count) return text

  const wordsArray = text.split(' ')
  return (
    wordsArray.slice(0, count).join(' ') +
    (count < wordsArray.length - 1 ? ellipsis : '')
  )
}
