import { SchoolYearInterface } from '../../../models/SchoolYear'

export type SchoolYearsState = {
  schoolYears: SchoolYearInterface[]
  selectedSchoolYear: SchoolYearInterface | null
}

export const ADD_SCHOOL_YEARS = 'ADD_SCHOOL_YEARS'
export const ADD_SINGLE_SCHOOL_YEAR = 'ADD_SCHOOL_YEAR'
export const SET_CURRENT_SCHOOL_YEAR = 'schoolYear/SET_CURRENT_SCHOOL_YEAR'

export type SchoolYearsActionType =
  | typeof ADD_SCHOOL_YEARS
  | typeof ADD_SINGLE_SCHOOL_YEAR
  | typeof SET_CURRENT_SCHOOL_YEAR

export type addSchoolYearsAction = {
  type: SchoolYearsActionType
  payload: SchoolYearInterface[]
}

export type currentSchoolYearAction = {
  type: SchoolYearsActionType
  payload: SchoolYearInterface
}

export type SchoolYearActions = addSchoolYearsAction | currentSchoolYearAction
