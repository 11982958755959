import {
  SCHOOL_RETRIEVED,
  SCHOOL_SELECTED,
  SchoolActionTypes,
  SchoolState,
  GLOBAL_SCHOOL_SELECTED,
} from './types'
import produce from 'immer'

const initialState: SchoolState = {
  schools: [],
  selectedSchoolId: null,
  selectGlobalSchoolId: null,
}

export default (
  state: SchoolState = initialState,
  action: SchoolActionTypes,
): SchoolState => {
  switch (action.type) {
    case SCHOOL_RETRIEVED:
      return produce(state, (draft) => {
        draft.schools = action.schools
      })
    case SCHOOL_SELECTED:
      return produce(state, (draft) => {
        draft.selectedSchoolId = action.selectedSchoolId
      })
    case GLOBAL_SCHOOL_SELECTED:
      return produce(state, (draft) => {
        draft.selectGlobalSchoolId = action.selectedSchoolId
      })
    default:
      return state
  }
}
