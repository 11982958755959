import BaseDAO from './BaseDAO'
import { Profile, collectionName } from '../models/Profile'
import { firestore } from '../services'
import { Query } from '../services/firebase/init'

export default class ProfileDAO extends BaseDAO<Profile> {
  collectionName = collectionName

  queryBySchoolId(schoolId: string): Query {
    return firestore
      .collection(collectionName)
      .where('schoolId', '==', schoolId)
  }

  async getBySchoolId(schoolId: string): Promise<Profile[]> {
    const query = this.queryBySchoolId(schoolId)
    return (await query.get()).docs.map(
      (docSnapshot) =>
        ({
          ...docSnapshot.data(),
          id: docSnapshot.id,
        } as Profile),
    )
  }
}
