import produce from 'immer'
import {
  ADD_LEVELS,
  ADD_SINGLE_LEVEL,
  CLEAR_LEVELS,
  LevelsActionsTypes,
  LevelState,
  REMOVE_LEVELS,
  UPDATE_LEVEL,
} from './types'
import { getIds } from '../../../utilities/functions'
import { Level } from '../../../models/Level'

const initialState: LevelState = {
  levels: [],
}

export default (
  state = initialState,
  action: LevelsActionsTypes,
): LevelState => {
  switch (action.type) {
    case ADD_LEVELS:
      return produce(state, (draft) => {
        draft.levels = action.payload
      })

    case ADD_SINGLE_LEVEL:
      return produce(state, (draft) => {
        draft.levels = [...state.levels, action.payload]
      })

    case REMOVE_LEVELS:
      const idsToDelete = getIds(action.payload as Level[])

      return produce(state, (draft) => {
        draft.levels = state.levels.filter((level) => {
          return !idsToDelete.includes(level.id as string)
        })
      })

    case UPDATE_LEVEL:
      const { payload } = action

      return produce(state, (draft) => {
        draft.levels = state.levels.map((level) => {
          if (level.id === payload.id) {
            level = payload
          }

          return level
        })
      })

    case CLEAR_LEVELS:
      return produce(state, (draft) => {
        draft.levels = []
      })

    default:
      return state
  }
}
