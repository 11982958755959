import {
  ADD_LEVELS,
  LevelsActionsTypes,
  ADD_SINGLE_LEVEL,
  REMOVE_LEVELS,
  UPDATE_LEVEL,
  CLEAR_LEVELS,
} from './types'
import { Level } from '../../../models/Level'
import { Dispatch } from 'redux'
import { LevelDAO } from '../../../dao/LevelDAO'

export function saveLevels(levels: Level[]): LevelsActionsTypes {
  return {
    type: ADD_LEVELS,
    payload: levels,
  }
}

export function addLevel(level: Level) {
  return (dispatch: Dispatch) =>
    dispatch({
      type: ADD_SINGLE_LEVEL,
      payload: level,
    })
}

export function removeLevels(levels: Level[]) {
  return (dispatch: Dispatch) =>
    dispatch({
      type: REMOVE_LEVELS,
      payload: levels,
    })
}

export const clearLevels = (): LevelsActionsTypes => ({
  type: CLEAR_LEVELS,
})

export function updateLevel(
  level: Level,
  attributes: { name: string },
): (dispatch: Dispatch) => LevelsActionsTypes {
  level.name = attributes.name

  return (dispatch: Dispatch) =>
    dispatch({
      type: UPDATE_LEVEL,
      payload: level,
    })
}

export const fetchLevels = (schoolId: string) => {
  return async (dispatch: Dispatch) => {
    const dao = new LevelDAO()
    const levels = (
      await dao
        .equals('schoolId', schoolId as string)
        .orderBy('createdAt', 'desc')
        .get()
    ).docs.map((level) => {
      return { id: level.id, ...level.data() } as Level
    })

    dispatch(saveLevels(levels))
  }
}
