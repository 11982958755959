import produce from 'immer'
import {
  PERMISSIONS_FETCHED,
  PermissionsActionType,
  PermissionState,
} from './types'
import defaultPermissions from '../../../data/defaultPermissions'
import { Permission } from '../../../models/Permission'

function savePermissionInLocalStorage(permissionState: PermissionState): void {
  try {
    localStorage.setItem(
      'permissions',
      JSON.stringify(permissionState.permissions),
    )
    localStorage.setItem(
      'permissionsMap',
      JSON.stringify(permissionState.permissionsMap),
    )
  } catch (e) {
    console.log(e)
  }
}

function permissionsFromLocalStorage(): PermissionState {
  try {
    return {
      permissions: JSON.parse(
        localStorage.getItem('permissions') as string,
      ) as Permission,
      permissionsMap: JSON.parse(
        localStorage.getItem('permissionsMap') as string,
      ),
    }
  } catch (e) {
    return {
      permissions: defaultPermissions,
      permissionsMap: {},
    }
  }
}

const initialState: PermissionState = {
  permissions: permissionsFromLocalStorage().permissions,
  permissionsMap: permissionsFromLocalStorage().permissionsMap,
}

export default (
  state = initialState,
  action: PermissionsActionType,
): PermissionState => {
  switch (action.type) {
    case PERMISSIONS_FETCHED:
      savePermissionInLocalStorage(action.payload)

      return produce(state, (draft) => {
        draft.permissions = action.payload.permissions
        draft.permissionsMap = action.payload.permissionsMap
      })
    default:
      return state
  }
}
