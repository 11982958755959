import BaseDAO from './BaseDAO'
import { Level, collectionName } from '../models/Level'
import { firestore } from '../services'
import { Query } from '../services/firebase/init'

export class LevelDAO extends BaseDAO<Level> {
  collectionName = collectionName

  queryManyBySchool(schoolId: string): Query {
    return firestore
      .collection(collectionName)
      .where('schoolId', '==', schoolId)
  }

  async getManyBySchool(schoolId: string): Promise<Level[]> {
    return (await this.queryManyBySchool(schoolId).get()).docs.map(
      (docItem) =>
        ({
          id: docItem.id,
          ...docItem.data(),
        } as Level),
    )
  }
}
