import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { USER_LOCAL_STORAGE_KEY } from './store/modules/auth/types'

const cachedUser = JSON.parse(
  localStorage.getItem(USER_LOCAL_STORAGE_KEY) as string,
)

export default (): any => {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY as string,
    plugins: [new BugsnagPluginReact()],
    appType: 'client',
    appVersion: process.env.REACT_APP_VERSION,
    user: cachedUser
      ? {
          id: cachedUser.uid,
          email: cachedUser.email,
          name: cachedUser.displayName,
        }
      : undefined,
  })
}
