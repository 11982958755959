import {
  SCHOOL_RETRIEVED,
  SchoolActionTypes,
  SCHOOL_SELECTED,
  GLOBAL_SCHOOL_SELECTED,
} from './types'
import SchoolDAO from '../../../dao/SchoolDAO'
import { Dispatch } from 'redux'
import { AppState } from '../../rootReducer'
import { School } from '../../../models/School'
import { addMessage } from '../alerts/actions'

const dao = new SchoolDAO()

export const schoolsRetrieved = (schools: School[]): SchoolActionTypes => ({
  type: SCHOOL_RETRIEVED,
  schools,
})

export const schoolSelected = (
  selectedSchoolId: string | null,
): SchoolActionTypes => ({
  type: SCHOOL_SELECTED,
  selectedSchoolId,
})

export const globalSchoolSelected = (
  selectedSchoolId: string | null,
): SchoolActionTypes => ({
  type: GLOBAL_SCHOOL_SELECTED,
  selectedSchoolId,
})

export const retrieveSchools = () => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const userData = getState().auth.userData

    if (userData?.schoolIds) {
      try {
        const schools = await dao.getManyByIds(userData?.schoolIds as string[])
        dispatch(schoolsRetrieved(schools))
      } catch (e) {
        dispatch(
          addMessage({ level: 'error', shortMessage: e.message, error: e }),
        )
      }
    }
  }
}

export const setSelectedSchool = (schoolId: string | null) => {
  return (dispatch: Dispatch) => dispatch(schoolSelected(schoolId))
}
