import Model from './Model'

export const collectionName = 'permissions'

export type PermissionElements = {
  create: boolean
  read: boolean
  update: boolean
  delete: boolean
  addStudent: boolean
  deleteStudent: boolean
}
export type PermissionSingleElement = keyof PermissionElements

export interface Permission extends Model {
  profileId: string
  AccountManagement: {
    update: boolean
  }
  ClassManagement: {
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
    addStudent: boolean
    deleteStudent: boolean
  }
  CommunicationManagement: {
    createCampaign: boolean
    readStats: boolean
  }
  ClassLevelManagement: {
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
  }
  CheckoutManagement: {
    read: boolean
    update: boolean
    closeDay: boolean
    delete: boolean
  }
  ProfileManagement: {
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
  }
  RegistrationManagement: {
    create: boolean
    read: boolean
    update: boolean
  }
  SchoolYearManagement: {
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
  }
  SchoolRecordManagement: {
    create: boolean
    read: boolean
    delete: boolean
  }
  SchoolingManagement: {
    read: boolean
  }
  StudentManagement: {
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
    transfer: boolean
  }
  UsersManagement: {
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
    ban: boolean
  }
  TeachersManagement: {
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
  }
  TimeTableManagement: {
    configure: boolean
    read: boolean
  }
  AssessmentsManagement: {
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
  }
}
