import produce from 'immer'
import {
  AuthorizationState,
  DATA_SAVING_AUTHORIZATION_CHANGED,
  DataSavingAction,
} from './type'

const initialState: AuthorizationState = {
  canSaveData: false,
}

export default (
  state = initialState,
  action: DataSavingAction,
): AuthorizationState => {
  switch (action.type) {
    case DATA_SAVING_AUTHORIZATION_CHANGED:
      return produce(state, (draft) => {
        draft.canSaveData = action.payload.canSaveData
      })

    default:
      return state
  }
}
