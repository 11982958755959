/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios'

export const get = async (
  url: string,
  options?: AxiosRequestConfig,
): Promise<any> => {
  const response = await axios.get(url, options)
  return response.data
}

export const getWithHeaders = async (
  url: string,
  options?: AxiosRequestConfig,
): Promise<any> => {
  const response = await axios.get(url, options)
  return [response.data, response.headers]
}

export const post = async (
  url: string,
  data: {},
  options?: AxiosRequestConfig,
): Promise<any> => {
  return (await axios.post(url, data, options)).data
}

export const deleteReq = async (
  url: string,
  options?: AxiosRequestConfig,
): Promise<any> => {
  return (await axios.delete(url, options)).data
}

export const patch = async (
  url: string,
  data: {},
  options?: AxiosRequestConfig,
): Promise<any> => {
  return (await axios.patch(url, data, options)).data
}
