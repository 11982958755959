import {
  ADD_MESSAGE,
  AlertActionsTypes,
  CLEAR_ALL_MESSAGES,
  CLEAR_MESSAGE,
  AlertState,
  AppMessage,
} from './types'
import { v4 as uuidv4 } from 'uuid'
import produce from 'immer'

const initialState: AlertState = {
  messages: [],
  messageIds: [],
}

export default (
  state: AlertState = initialState,
  action: AlertActionsTypes,
): AlertState => {
  switch (action.type) {
    case ADD_MESSAGE:
      const uuid = uuidv4()
      return produce(state, (draft) => {
        draft.messages = [{ ...action.message, uuid }, ...draft.messages]
        draft.messageIds = [uuid, ...draft.messageIds]
      })
    case CLEAR_MESSAGE:
      return produce(state, (draft) => {
        draft.messages = draft.messages?.filter(
          (message) => message.uuid !== action.uuid,
        ) as AppMessage[]

        draft.messageIds = draft.messageIds.filter(
          (uuid) => uuid !== action.uuid,
        )
      })
    case CLEAR_ALL_MESSAGES:
      return produce(state, (draft) => {
        draft.messages = []
        draft.messageIds = []
      })

    default:
      return state
  }
}
