import {
  combineReducers,
  ReducersMapObject,
  Action,
  CombinedState,
  Reducer,
} from 'redux'
import authReducer from './modules/auth/reducer'
import alertsReducer from './modules/alerts/reducer'
import schoolsReducer from './modules/schools/reducer'
import usersReducer from './modules/users/reducer'
import schoolYearsReducer from './modules/schoolYear/reducer'
import { AuthState, LOGOUT_SUCCESS } from './modules/auth/types'
import { AlertState } from './modules/alerts/types'
import { LayoutState } from './modules/layout/types'
import layoutReducer from './modules/layout/reducer'
import { SchoolState } from './modules/schools/types'
import { UserState } from './modules/users/types'
import { SchoolYearsState } from './modules/schoolYear/types'
import { ProfileState } from './modules/profiles/types'
import profilesReducer from './modules/profiles/reducer'
import { LevelState } from './modules/levels/types'
import levelsReducer from './modules/levels/reducer'
import permissionReducer from './modules/permissions/reducer'
import { PermissionState } from './modules/permissions/types'
import { ClassroomState } from './modules/classrooms/types'
import ClassroomReducer from './modules/classrooms/reducer'
import AuthorizationReducer from './modules/authorizations/reducer'
import { AuthorizationState } from './modules/authorizations/type'
import { CheckoutState } from './modules/checkout/types'
import CheckoutReducer from './modules/checkout/reducer'

export type AppState = {
  auth: AuthState
  alerts: AlertState
  layout: LayoutState
  schools: SchoolState
  users: UserState
  schoolYears: SchoolYearsState
  profiles: ProfileState
  levels: LevelState
  permissions: PermissionState
  classrooms: ClassroomState
  authorizations: AuthorizationState
  checkout: CheckoutState
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (): Reducer<CombinedState<AppState>, Action> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reducersMap: ReducersMapObject<AppState, any> = {
    auth: authReducer,
    alerts: alertsReducer,
    layout: layoutReducer,
    schools: schoolsReducer,
    users: usersReducer,
    schoolYears: schoolYearsReducer,
    profiles: profilesReducer,
    levels: levelsReducer,
    permissions: permissionReducer,
    classrooms: ClassroomReducer,
    authorizations: AuthorizationReducer,
    checkout: CheckoutReducer,
  }

  return combineReducers(reducersMap)
}

const appReducer = rootReducer()

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mainReducer = (
  state: AppState | undefined,
  action: { type: typeof LOGOUT_SUCCESS },
) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined
  }

  return appReducer(state, action)
}

export default mainReducer
