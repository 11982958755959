import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useSnackbar, SnackbarAction } from 'notistack'
import { Button } from '@material-ui/core'
import { clearMessage } from '../../store/modules/alerts/actions'
import { AppState } from '../../store/rootReducer'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapState = (state: AppState) => ({
  messages: state.alerts.messages,
})

const mapDispatch = { clearMessage }

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  uuid: string
}

const AlertItem: React.FC<Props> = (props) => {
  const { messages, uuid, clearMessage } = props
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const handleClose = React.useCallback(() => {
    closeSnackbar(uuid)
  }, [closeSnackbar, uuid])

  useEffect(() => {
    const message = messages?.find((message) => message.uuid === uuid)
    let persist = false
    if (message?.level === 'error') {
      persist = true
    }

    enqueueSnackbar(message?.shortMessage, {
      variant: message?.level,
      autoHideDuration: 5000,
      // eslint-disable-next-line react/display-name
      action: (): SnackbarAction => (
        <>
          <Button onClick={handleClose}>Fermer</Button>
        </>
      ),
      preventDuplicate: true,
      persist: persist,
      onClose: () => clearMessage(uuid),
      key: uuid,
    })
  }, [clearMessage, enqueueSnackbar, handleClose, messages, uuid])

  return <></>
}

export default connector(AlertItem)
