import { createSelector } from 'reselect'
import { AppState } from '../rootReducer'
import { User } from '../modules/auth/types'
import { User as UserData } from '../../models/User'
import { getInitials } from '../../helpers/functions'
import { Profile } from '../../models/Profile'

export const userSelector = (state: AppState): User | null => state.auth.user
const userDataSelector = (state: AppState): UserData | null =>
  state.auth.userData
const profileSelector = (state: AppState): Profile | null => state.auth.profile

export const userInitialsSelector = createSelector(userSelector, (user) =>
  getInitials(user?.displayName as string),
)

export const authPersistenceSelector = (state: AppState): string =>
  state.auth.persistance

export const photoURLSelector = createSelector(
  userSelector,
  (user) => user?.photoURL,
)

export const userFormSelector = createSelector(
  userSelector,
  userDataSelector,
  (user, userData) => ({
    displayName: user?.displayName,
    email: user?.email,
    phoneNumber: userData?.phoneNumber,
    photoURL: user?.photoURL,
  }),
)

export const uidSelector = createSelector(
  userSelector,
  (user) => user?.uid as string,
)

export const schoolIdsSelector = createSelector(
  userDataSelector,
  (userData) => userData?.schoolIds,
)

export const isSupervisorSelector = createSelector(
  profileSelector,
  (profile) => profile?.name === 'Superviseur' && !profile?.schoolId,
)

export const isFirstLogin = createSelector(
  userDataSelector,
  (userData) => userData?.isFirstTime,
)
