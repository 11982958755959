import { applyMiddleware, createStore, StoreEnhancer, Store } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from './rootReducer'

function configureStore(preloadedState: {} | undefined): Store {
  const middleware = [thunkMiddleware]
  const middlewareEnhancer: StoreEnhancer = applyMiddleware(...middleware)

  const enhancers = [middlewareEnhancer]
  // const composedEnhancers = composeWithDevTools({ trace: true })
  const composedEnhancers: StoreEnhancer = composeWithDevTools(...enhancers)

  const store: Store = createStore(
    rootReducer,
    preloadedState,
    composedEnhancers,
  )
  // const store: Store = createStore(
  //   rootReducer,
  //   preloadedState,
  //   composedEnhancers(applyMiddleware(...middleware)),
  // )

  // Webpack HMR
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    // eslint-disable-next-line no-unused-expressions
    module.hot?.accept('./rootReducer', () => store.replaceReducer(rootReducer))
  }

  return store
}

const store = configureStore({})

export default store
