import { Permission } from '../../../models/Permission'

export const PERMISSIONS_FETCHED = 'permissions/PermissionsFetched'
export const PERMISSIONS_MAP_SAVED = 'permissions/PermissionsMapSaved'

export type PermissionMap = {
  [key: string]: boolean
}

export type PermissionState = {
  permissions: Permission
  permissionsMap: PermissionMap
}

export interface PermissionsFetched {
  type: typeof PERMISSIONS_FETCHED
  payload: {
    permissions: Permission
    permissionsMap: PermissionMap
  }
}

export interface PermissionMapSaved {
  type: typeof PERMISSIONS_MAP_SAVED
  payload: PermissionMap
}

export type PermissionsActionType = PermissionsFetched | PermissionMapSaved
