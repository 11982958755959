import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles(({ spacing }: Theme) => ({
  block: {
    display: 'block',
  },
  flex: {
    display: 'flex',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  justifyContentBetween: {
    justifyContent: 'space-between',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  flexRow: {
    flexDirection: 'row',
  },
  p4: {
    padding: spacing(4),
  },
  p3: {
    padding: spacing(3),
  },
  p2: {
    padding: spacing(2),
  },
  p1: {
    padding: spacing(1),
  },
  py4: {
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
  },
  py3: {
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
  },
  py2: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  py1: {
    paddingTop: spacing(1) + 'px !important',
    paddingBottom: spacing(1) + 'px !important',
  },
  py0: {
    paddingTop: spacing(0),
    paddingBottom: spacing(0),
  },
  px4: {
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
  },
  px3: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
  px2: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
  px1: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
  px0: {
    paddingLeft: spacing(0),
    paddingRight: spacing(0),
  },
  pl4: {
    paddingLeft: spacing(4),
  },
  pl3: {
    paddingLeft: spacing(3),
  },
  pl2: {
    paddingLeft: spacing(2),
  },
  pl1: {
    paddingLeft: spacing(1),
  },
  pl0: {
    paddingLeft: spacing(0),
  },
  pr4: {
    paddingRight: spacing(4),
  },
  pr3: {
    paddingRight: spacing(3),
  },
  pr2: {
    paddingRight: spacing(2),
  },
  pr1: {
    paddingRight: spacing(1),
  },
  pr0: {
    paddingRight: spacing(0),
  },

  pt0: {
    paddingTop: spacing(0),
  },
  pt1: {
    paddingTop: spacing(1),
  },
  pt2: {
    paddingTop: spacing(2),
  },
  pt3: {
    paddingTop: spacing(3),
  },
  pt4: {
    paddingTop: spacing(4),
  },

  pb0: {
    paddingBottom: spacing(0),
  },
  pb1: {
    paddingBottom: spacing(1),
  },
  pb2: {
    paddingBottom: spacing(2),
  },
  pb3: {
    paddingBottom: spacing(3),
  },
  pb4: {
    paddingBottom: spacing(4),
  },

  // Margin
  mr4: {
    marginRight: spacing(4),
  },
  mr3: {
    marginRight: spacing(3),
  },
  mr2: {
    marginRight: spacing(2),
  },
  mr1: {
    marginRight: spacing(1),
  },
  mt1: {
    marginTop: spacing(1),
  },
  mt2: {
    marginTop: spacing(2),
  },
  mt3: {
    marginTop: spacing(3),
  },
  mt4: {
    marginTop: spacing(4),
  },

  mb4: {
    marginBottom: spacing(4),
  },
  mb3: {
    marginBottom: spacing(3),
  },
  mb2: {
    marginBottom: spacing(2),
  },
  mb1: {
    marginBottom: spacing(1),
  },
  mb0: {
    marginBottom: spacing(0),
  },
  my4: {
    marginTop: spacing(4),
    marginBottom: spacing(4),
  },
  my3: {
    marginTop: spacing(3),
    marginBottom: spacing(3),
  },
  my2: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
  my1: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  my0: {
    marginTop: spacing(0),
    marginBottom: spacing(0),
  },
  mxAuto: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  //
  w100: {
    width: '100%',
  },
  h100: {
    height: '100%',
  },

  // Colors
  bgWhite: {
    backgroundColor: 'white',
  },
  bgRed: {
    backgroundColor: '#f53b57',
    '&:hover': {
      backgroundColor: '#f54d6a',
    },
  },
  bgMainBlue: {
    backgroundColor: '#392e81',
  },
  bgSuccess: {
    backgroundColor: '#8cc966',
  },
  textWhite: {
    color: 'white !important',
  },

  // Text
  fontWeightBold: {
    fontWeight: 'bold',
  },
  textBold: {
    fontWeight: 'bold',
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  textCenter: {
    textAlign: 'center',
  },
  imgFluid: {
    width: '100%',
    height: 'auto',
  },
}))
