import { firestore } from '../services'
import firebase from 'firebase/app'
import Model from '../models/Model'

export default abstract class BaseDAO<T extends Model> {
  collectionName = ''

  async getAll(): Promise<T[]> {
    let collectionData: T[] = []

    try {
      const querySnapshot = await firestore
        .collection(this.collectionName)
        .get()
      collectionData = querySnapshot.docs.map((document) => {
        return { id: document.id, ...document.data() } as T
      })

      return collectionData
    } catch (e) {
      return collectionData
    }
  }

  queryById(
    docId: string,
  ): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
    return firestore.collection(this.collectionName).doc(docId)
  }

  async getById(docId: string): Promise<T> {
    const data = await this.queryById(docId).get()

    return { ...data.data(), id: data.id } as T
  }

  async getManyByIds(ids: string[]): Promise<T[]> {
    const promises = ids.map(async (id) => {
      const snapshot = await firestore
        .collection(this.collectionName)
        .doc(id)
        .get()

      return { ...snapshot.data(), id: snapshot.id } as T
    })

    return Promise.all(promises)
  }

  create(
    data: Omit<T, 'createdAt' | 'updatedAt'>,
  ): Promise<firebase.firestore.DocumentReference> {
    const model = {
      ...data,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: null,
    }
    return firestore.collection(this.collectionName).add(model)
  }

  createMany(
    data: Omit<T, 'createdAt' | 'updatedAt'>[],
  ): Promise<firebase.firestore.DocumentReference[]> {
    return Promise.all(
      data.map((doc) => firestore.collection(this.collectionName).add(doc)),
    )
  }

  async update(docId: string, data: Partial<T>): Promise<void> {
    const model = {
      ...data,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }
    await firestore.collection(this.collectionName).doc(docId).update(model)
  }

  async deleteDoc(docId: string): Promise<void> {
    await firestore.collection(this.collectionName).doc(docId).delete()
  }

  contains(
    key: keyof T,
    value: string,
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    return firestore
      .collection(this.collectionName)
      .where(key as string, 'array-contains', value)
  }

  in(
    key: keyof T,
    values: string[],
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    return firestore
      .collection(this.collectionName)
      .where(key as string, 'in', values)
  }

  equals(
    key: keyof T,
    value: string,
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    return firestore
      .collection(this.collectionName)
      .where(key as string, '==', value)
  }

  async deleteMany(resources: T[]): Promise<void[]> {
    const promises = resources.map((resource) =>
      this.deleteDoc(resource.id as string),
    )
    return await Promise.all(promises)
  }

  async count(): Promise<number> {
    return (await firestore.collection(this.collectionName).get()).docs.length
  }
}
