import {
  Grid,
  Typography,
  Button,
  makeStyles,
  createStyles,
} from '@material-ui/core'
import { RefreshOutlined } from '@material-ui/icons'
import React from 'react'
import useUtilitiesStyles from '../../../theme/useUtilitiesStyles'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100vh',
      width: '100vw',
    },
  }),
)

const ErrorView: React.FC = () => {
  const styles = useStyles()
  const utilities = useUtilitiesStyles()

  const handleRefresh = (): void => {
    window.location.reload()
  }

  return (
    <Grid
      container
      alignItems='center'
      justify='center'
      direction='column'
      className={styles.root}>
      <Typography variant='h4' className={utilities.mb4}>
        Oh oh... Quelque chose n&apos;a pas fonctionné correctement :-(
      </Typography>
      <Typography variant='h5' className={utilities.mb4}>
        Nous nous en occupons, en attendant veuillez reéssayer.
      </Typography>

      <Button
        color='primary'
        variant='contained'
        onClick={handleRefresh}
        startIcon={<RefreshOutlined />}>
        Reéssayer
      </Button>
    </Grid>
  )
}

export default ErrorView
