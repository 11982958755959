import React, { FC, Suspense } from 'react'
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom'

import RedirectIfAuth from './RedirectIfAuth'
import Private from './Private'
import Alerts from '../components/Alerts/Alerts'
// import { AppState } from '../store/rootReducer'
import PageLoading from '../components/Layout/PageLoading/PageLoading'
import NotFoundErrorPage from '../pages/errors/404/NotFoundErrorPage'

const DashboardPage = React.lazy(() =>
  import('../pages/dashboard/DashboardPage'),
)

const LoginPage = React.lazy(() => import('../pages/login/LoginPage'))

const Routes: FC = () => {
  return (
    <Router>
      {/* Routing Zone */}
      <Alerts />
      <Suspense fallback={<PageLoading fullScreen spinnerSize={64} />}>
        <Switch>
          <Route exact path='/'>
            <Redirect to='/login' />
          </Route>
          <RedirectIfAuth
            exact
            path='/login'
            component={LoginPage}
            redirectTo='/dashboard'
          />
          <Private
            component={DashboardPage}
            path='/dashboard/'
            redirectTo='/login'
          />
          <Route component={NotFoundErrorPage} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Routes
