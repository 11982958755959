export const CHANGE_OPERATION_DATE = 'CHECKOUT/ChangeOperationDate'
export const CLOSE_OPERATION_DATE = 'CHECKOUT/CloseOperationDate'

export type CheckoutState = {
  operationDate: Date | null
  isClosed: boolean
}

export interface OperationDateChanged {
  type: typeof CHANGE_OPERATION_DATE
  payload: {
    operationDate: Date | null
    isClosed: boolean
  }
}

export interface OperationDateClosed {
  type: typeof CLOSE_OPERATION_DATE
  payload: {
    isClosed: boolean
  }
}

export type CheckoutActionTypes = OperationDateChanged | OperationDateClosed
