import { createMuiTheme, Theme } from '@material-ui/core/styles'

const theme: Theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#6a58b1',
      main: '#392e81',
      dark: '#000854',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#2e84c9',
      light: '#6cb3fc',
      dark: '#005898',
      contrastText: '#ffffff',
    },
    common: {
      white: '#fff',
      black: '#000',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
})

export default theme
