import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

/**
 * Firebase project initialization
 */
const app = firebase.initializeApp(config)

/**
 * Auth module initialization
 */
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
firebase.auth().languageCode = 'fr'

/**
 * Firestore module initialization
 */
const options: firebase.firestore.Settings =
  process.env.NODE_ENV === 'development'
    ? {
        ignoreUndefinedProperties: true,
        // host: 'localhost:8080',
        // ssl: false,
      }
    : { ignoreUndefinedProperties: true }
firebase.firestore().settings(options)
firebase
  .firestore()
  .enablePersistence()
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn(
        "Can't enable offline mode because the app is running in more than on tab. Please keep only on tab and reload the page.",
      )
    } else if (err.code === 'unimplemented') {
      console.warn(
        "Can't enable persistence because your browser does not support it. Please use a more recent browser.",
      )
    }
  })

/**
 * Types alias
 */
export type QuerySnapshot = firebase.firestore.QuerySnapshot<
  firebase.firestore.DocumentData
>
export type QuerySnapshotDocument = firebase.firestore.QueryDocumentSnapshot<
  firebase.firestore.DocumentData
>
export type Query = firebase.firestore.Query<firebase.firestore.DocumentData>

/**
 * Exporting modules for easy usage
 */
export const db: firebase.firestore.Firestore = firebase.firestore()
export const auth: firebase.auth.Auth = firebase.auth()
export const storage: firebase.storage.Storage = firebase.storage(app)
