export const DATA_SAVING_AUTHORIZATION_CHANGED = 'authorization/SaveData'

export type AuthorizationActionTypes = typeof DATA_SAVING_AUTHORIZATION_CHANGED

export type DataSavingPayload = {
  canSaveData: boolean
}

export type DataSavingAction = {
  type: AuthorizationActionTypes
  payload: DataSavingPayload
}

export type AuthorizationState = {
  canSaveData: boolean
}
