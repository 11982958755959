import React from 'react'
import { AppState } from '../store/rootReducer'
import { ConnectedProps, connect } from 'react-redux'
import {
  RouteProps,
  RouteComponentProps,
  Route,
  Redirect,
} from 'react-router-dom'

const mapState = (state: AppState): { isLogged: boolean | null } => ({
  isLogged: state.auth.isLogged,
})

const connector = connect(mapState, {})

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux &
  RouteProps & {
    component: React.FC<RouteComponentProps>
    redirectTo: string
  }

const RedirectIfAuth: React.FC<Props> = (props: Props) => {
  const { component: Component, isLogged, redirectTo, ...rest } = props

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLogged ? <Component {...props} /> : <Redirect to={redirectTo} />
      }
    />
  )
}

export default connector(RedirectIfAuth)
