import { firestore } from '../services'
import firebase from 'firebase/app'
import { User, collectionName } from '../models/User'
import BaseDAO from './BaseDAO'

export default class UserDAO extends BaseDAO<User> {
  collectionName = collectionName

  queryBySchoolId(
    schoolId: string,
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    return firestore
      .collection(this.collectionName)
      .where('schoolIds', 'array-contains', schoolId)
  }
}
