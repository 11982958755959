import Model from './Model'

export const SCHOOL_PROFILE_TYPE = 'SCHOOL'
export const SUPERVISOR_PROFILE_NAME = 'Superviseur'
export const collectionName = 'profiles'

export interface Profile extends Model {
  name: string
  schoolId: string
  type: typeof SCHOOL_PROFILE_TYPE
}

export enum ProfileLabels {
  Enseignant = 'Enseignant',
  Administrateur = 'Administrateur',
  Administration = 'Administration',
  Caissière = 'Caissière',
}

export const FROZEN_PROFILES = [ProfileLabels.Enseignant as string]
