import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import App from './App'
import Bugsnag from '@bugsnag/js'

// import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './store/configureStore'
import './services/firebase/init'
import { enableAllPlugins } from 'immer'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/fr'
import ErrorView from './components/Layout/ErrorBoundary/ErrorView'
import setupBugsnag from './setupBugsnag'

moment.locale('fr')

// Enable all immer plugins
enableAllPlugins()

setupBugsnag()

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(
  React,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) as any

const renderApp = (): void =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Provider store={store}>
            <App />
          </Provider>
        </MuiPickersUtilsProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  // eslint-disable-next-line no-unused-expressions
  module.hot?.accept('./App', renderApp)
}

renderApp()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
