import Bugsnag from '@bugsnag/js'
import algoliasearch, { SearchIndex } from 'algoliasearch/lite'
import { getSearchKey } from '../api/searchApi'

// const client = algoliasearch(
//   process.env.REACT_APP_ALGOLIA_ID as string,
//   process.env.REACT_APP_ALGOLIA_API_KEY as string,
// )
// const studentIndex = client.initIndex(
//   process.env.REACT_APP_STUDENT_INDEX as string,
// )

const getStudentSearchClient = (): Promise<SearchIndex | null> => {
  return getSearchKey()
    .then((key: string) => {
      const client = algoliasearch(
        process.env.REACT_APP_ALGOLIA_ID as string,
        key,
      )
      return client.initIndex(process.env.REACT_APP_STUDENT_INDEX as string)
    })
    .catch((error) => {
      Bugsnag.notify(error)
      return null
    })
}

export default { getStudentSearchClient }
