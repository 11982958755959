import firebase from 'firebase/app'
import { User as UserData } from '../../../models/User'
import { Profile } from '../../../models/Profile'

export type User = Readonly<firebase.User>

export type AuthMode = 'reset' | 'register' | 'login'

export type AuthState = {
  user: User | null
  userData: UserData | null
  profile: Profile | null
  isLogged: boolean | null
  isLoading: boolean | undefined
  mode: AuthMode
  persistance: firebase.auth.Auth.Persistence
}

export type Credentials = {
  email: string
  password: string
}

export const LOGIN = 'auth/login'
export const LOGOUT = 'auth/logout'
export const LOGOUT_SUCCESS = 'auth/logoutSuccess'
export const LOGIN_SUCCESS = 'auth/loginSuccess'
export const LOGIN_FAILED = 'auth/loginFailed'
export const AUTH_STATE_UPDATED = 'auth/authStateUpdated'
export const LOADING = 'auth/loading'
export const MODE_CHANGED = 'auth/modeChange'
export const PASSWORD_RESET_EMAIL_SENT = 'auth/passwordResetEmailSent'
export const PASSWORD_RESET_EMAIL_FAILED = 'auth/passwordResetEmailFailed'

export const USER_LOCAL_STORAGE_KEY = 'akylixUser'

interface Login {
  type: typeof LOGIN
}

interface AuthStateUpdated {
  type: typeof AUTH_STATE_UPDATED
  user: User
  userData: UserData
  profile: Profile
}

interface LoginFailed {
  type: typeof LOGIN_FAILED
}

interface LoginSuccess {
  type: typeof LOGIN_SUCCESS
  user: User
  userData: UserData
  profile: Profile
  authPersistance: firebase.auth.Auth.Persistence
}

interface LogoutSuccess {
  type: typeof LOGOUT_SUCCESS
}

interface Loading {
  type: typeof LOADING
}

interface ModeChanged {
  type: typeof MODE_CHANGED
  mode: AuthMode
}

interface PasswordResetEmailSent {
  type: typeof PASSWORD_RESET_EMAIL_SENT
}

interface PasswordResetEmailFailed {
  type: typeof PASSWORD_RESET_EMAIL_FAILED
}

export type AuthActionTypes =
  | Login
  | LoginFailed
  | LoginSuccess
  | LogoutSuccess
  | AuthStateUpdated
  | Loading
  | ModeChanged
  | PasswordResetEmailSent
  | PasswordResetEmailFailed
