import produce from 'immer'
import {
  CHANGE_OPERATION_DATE,
  CheckoutActionTypes,
  CheckoutState,
  CLOSE_OPERATION_DATE,
} from './types'

const initialState: CheckoutState = {
  operationDate: null,
  isClosed: true,
}

export default (
  state = initialState,
  action: CheckoutActionTypes,
): CheckoutState => {
  switch (action.type) {
    case CHANGE_OPERATION_DATE:
      return produce(state, (draft) => {
        draft.operationDate = action.payload.operationDate
        draft.isClosed = action.payload.isClosed
      })

    case CLOSE_OPERATION_DATE:
      return produce(state, (draft) => {
        draft.isClosed = action.payload.isClosed
      })

    default:
      return state
  }
}
