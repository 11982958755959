import React from 'react'
import {
  makeStyles,
  createStyles,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() =>
  createStyles({
    // root: {
    //   // display: 'flex'
    //   // flex: 1,
    //   // flexGrow: 1,
    // },
    fullScreen: {
      height: '100vh',
      width: '100vw',
    },
  }),
)

type Props = {
  fullScreen?: boolean
  spinnerSize?: number
}

const PageLoading: React.FC<Props> = ({ fullScreen, spinnerSize }) => {
  const styles = useStyles()

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className={clsx(fullScreen && styles.fullScreen)}>
      <CircularProgress size={spinnerSize} />
    </Grid>
  )
}

PageLoading.defaultProps = {
  spinnerSize: 48,
}

export default PageLoading
