import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  AUTH_STATE_UPDATED,
  LOADING,
  AuthState,
  AuthActionTypes,
  USER_LOCAL_STORAGE_KEY,
  MODE_CHANGED,
  PASSWORD_RESET_EMAIL_SENT,
  PASSWORD_RESET_EMAIL_FAILED,
} from './types'
import produce from 'immer'
import firebase from 'firebase/app'

const initialState: AuthState = {
  user: null,
  isLogged: !!localStorage.getItem(USER_LOCAL_STORAGE_KEY),
  isLoading: false,
  mode: 'login',
  userData: null,
  profile: null,
  persistance: firebase.auth.Auth.Persistence.SESSION,
}

export default (
  state: AuthState = initialState,
  action: AuthActionTypes,
): AuthState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      // Set user in local storage to accelerate login state checks
      localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(action.user))

      return produce(state, (draft) => {
        draft.user = action.user
        draft.isLoading = false
        draft.isLogged = true
        draft.userData = action.userData
        draft.profile = action.profile
        draft.persistance = action.authPersistance
      })
    case LOGIN_FAILED:
    case LOGOUT_SUCCESS:
      localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
      return produce(state, (draft) => {
        draft.user = null
        draft.isLoading = false
        draft.isLogged = false
        draft.userData = null
        draft.profile = null
      })
    case AUTH_STATE_UPDATED:
      // Remove localStorage data if user not authenticated
      if (!action.user) {
        localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
      }

      return produce(state, (draft) => {
        draft.isLogged = !!action.user
        draft.user = action.user
        draft.userData = action.userData
        draft.profile = action.profile
      })
    case LOADING:
      return produce(state, (draft) => {
        draft.isLoading = true
      })
    case MODE_CHANGED:
      return produce(state, (draft) => {
        draft.mode = action.mode
      })
    case PASSWORD_RESET_EMAIL_SENT:
    case PASSWORD_RESET_EMAIL_FAILED:
      return produce(state, (draft) => {
        draft.isLoading = false
      })
    default:
      return state
  }
}
