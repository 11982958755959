export const USER_SELECTED = 'users/userSelected'
export const SELECTION_CLEARED = 'users/selectionCleared'

export type SelectionType = 'edit' | 'delete'

export type UserState = {
  selectedUsersId: string[] | null
  mode: SelectionType | null
}

interface SelectionCleared {
  type: typeof SELECTION_CLEARED
}

interface UserSelected {
  type: typeof USER_SELECTED
  selectedUsersId: string[]
  mode: SelectionType
}

export type UsersActionsTypes = UserSelected | SelectionCleared
