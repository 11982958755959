import { Profile } from '../../../models/Profile'

export const SCHOOL_PROFILES_RETRIEVED = 'profiles/schoolProfileRetrieved'

export type ProfileState = {
  profiles: Profile[] | null
}

interface SchoolProfilesRetrieved {
  type: typeof SCHOOL_PROFILES_RETRIEVED
  profiles: Profile[] | null
}

export type ProfileActionTypes = SchoolProfilesRetrieved
