import {
  LayoutState,
  DRAWER_TOGGLED,
  LayoutActionTypes,
  ACCOUNT_FORM_TOGGLED,
  STUDENT_TAB_CHANGED,
  SETTINGS_CURRENT_TAB_CHANGED,
  CONFIG_CURRENT_TAB_CHANGED,
  CLASSROOM_SELECTOR_NODE_TOGGLED,
  SCHOOLING_PAGE_CURRENT_TAB_CHANGED,
} from './types'
import produce from 'immer'

const initialState: LayoutState = {
  isDrawerOpen: false,
  userAccountFormOpen: false,
  studentCurrentTab: 0,
  settingsCurrentTab: 0,
  configCurrentTab: 0,
  classroomSelectorToggledNodes: [],
  schoolingPageCurrentTab: 0,
}

export default (
  state: LayoutState = initialState,
  action: LayoutActionTypes,
): LayoutState => {
  switch (action.type) {
    case DRAWER_TOGGLED:
      return produce(state, (draft) => {
        draft.isDrawerOpen = !draft.isDrawerOpen
      })
    case ACCOUNT_FORM_TOGGLED:
      return produce(state, (draft) => {
        draft.userAccountFormOpen = !state.userAccountFormOpen
      })
    case STUDENT_TAB_CHANGED:
      return produce(state, (draft) => {
        draft.studentCurrentTab = action.index
      })
    case SETTINGS_CURRENT_TAB_CHANGED:
      return produce(state, (draft) => {
        draft.settingsCurrentTab = action.index
      })
    case CONFIG_CURRENT_TAB_CHANGED:
      return produce(state, (draft) => {
        draft.configCurrentTab = action.index
      })
    case CLASSROOM_SELECTOR_NODE_TOGGLED:
      return produce(state, (draft) => {
        draft.classroomSelectorToggledNodes = action.nodeIds
      })
    case SCHOOLING_PAGE_CURRENT_TAB_CHANGED:
      return produce(state, (draft) => {
        draft.schoolingPageCurrentTab = action.index
      })

    default:
      return state
  }
}
