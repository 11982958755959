import { get } from './axios'
import store from '../store/configureStore'
import { AppState } from '../store/rootReducer'

const baseUrl = process.env.REACT_APP_ALGOLIA_SEARCH_KEY_API

/**
 * Get algolia search key to secure user search
 *
 * @export
 * @returns {Promise<string>}
 */
export async function getSearchKey(): Promise<string> {
  const {
    auth: { user },
  }: AppState = store.getState()

  const idToken = await user?.getIdToken()

  try {
    const { key } = await get(`${baseUrl}/getSearchKey`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })

    return key
  } catch (e) {
    throw new Error(e.response.errors)
  }
}
