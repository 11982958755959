import Model from '../models/Model'
import store from '../store/configureStore'
import { AppState } from '../store/rootReducer'
import { SUPERVISOR_PROFILE_NAME } from '../models/Profile'
// import Subject from '../models/Subject'

export function sortObjectKeys<T>(objectParam: T): { [key: string]: any } | T {
  const newObject: { [key: string]: any } = {}

  Object.keys(objectParam)
    .sort()
    .forEach((key: string | keyof T) => {
      newObject[key as string] = objectParam[key as keyof T]
    })

  return newObject
}

export function getIds(models: Model[]): string[] {
  return models.map((model: Model) => {
    return model.id as string
  })
}

type ObjectMap = { [key: string]: boolean }

export function flatObjectToMap(
  current: any,
  parent = '',
  permissionMap: ObjectMap = {},
): ObjectMap {
  if (typeof current === 'object') {
    for (const key of Object.keys(current)) {
      const rootNode = `${parent}${parent ? '.' : ''}${key}`
      flatObjectToMap(current[key], rootNode, permissionMap)
    }
  } else {
    permissionMap[parent] = !!current
  }

  return permissionMap
}

export function hasPermission(permissionName: string): boolean {
  try {
    const {
      permissions: { permissionsMap },
      auth: { profile },
    }: AppState = store.getState()

    if (profile && profile.name === SUPERVISOR_PROFILE_NAME && permissionName) {
      return permissionName.indexOf('read') > 0
    }

    // return true
    return permissionsMap[permissionName]
  } catch (error) {
    console.error(error)
    return false
  }
}

export function doesNotHavePermission(permissionName: string): boolean {
  return !hasPermission(permissionName)
}

export function canSaveNewData(): boolean {
  return true

  // const { authorizations }: AppState = store.getState()

  // return authorizations.canSaveData
}

export function customGetContrastColor(
  red: number,
  green: number,
  blue: number,
): string {
  const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255

  return luminance < 0.7 ? '#fff' : '#2f2f2f'
}

export function pluralize(word: string, count: number): string {
  if (count > 1) {
    return word + 's'
  }
  return word
}
