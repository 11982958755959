import {
  ProfileActionTypes,
  SCHOOL_PROFILES_RETRIEVED,
  ProfileState,
} from './types'
import produce from 'immer'

const initialState: ProfileState = {
  profiles: null,
}

export default (
  state: ProfileState = initialState,
  action: ProfileActionTypes,
): ProfileState => {
  switch (action.type) {
    case SCHOOL_PROFILES_RETRIEVED:
      return produce(state, (draft) => {
        draft.profiles = action.profiles
      })

    default:
      return state
  }
}
