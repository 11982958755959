import { Level } from '../../../models/Level'

export const ADD_LEVELS = 'LEVELS/AddLevels'
export const ADD_SINGLE_LEVEL = 'LEVELS/AddSingleLevel'
export const REMOVE_LEVELS = 'LEVELS/RemoveLevels'
export const UPDATE_LEVEL = 'LEVELS/UpdateLevel'
export const CLEAR_LEVELS = 'levels/clear'

export type LevelState = {
  levels: Level[]
}

export interface LevelsFetched {
  type: typeof ADD_LEVELS
  payload: Level[]
}

export interface RemoveLevels {
  type: typeof REMOVE_LEVELS
  payload: Level[]
}

export interface SaveLevel {
  type: typeof ADD_SINGLE_LEVEL
  payload: Level
}

export interface UpdateLevel {
  type: typeof UPDATE_LEVEL
  payload: Level
}

export interface ClearLevels {
  type: typeof CLEAR_LEVELS
}

export type LevelsActionsTypes =
  | LevelsFetched
  | SaveLevel
  | RemoveLevels
  | UpdateLevel
  | ClearLevels
