import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { Dispatch } from 'redux'
import { auth } from '../services'
import { authStateUpdated } from '../store/modules/auth/actions'
import { User } from '../store/modules/auth/types'
import { User as UserData } from '../models/User'
import UserDAO from '../dao/UserDAO'
import {
  globalSchoolSelected,
  schoolsRetrieved,
} from '../store/modules/schools/actions'
import { Profile, SUPERVISOR_PROFILE_NAME } from '../models/Profile'
import ProfileDAO from '../dao/ProfileDAO'
import PermissionDAO from '../dao/PermissionDAO'
import { schoolProfilesRetrieved } from '../store/modules/profiles/actions'
import { fetchLevels } from '../store/modules/levels/actions'
import { Permission } from '../models/Permission'
import { permissionsFetched } from '../store/modules/permissions/action'
import { flatObjectToMap } from '../utilities/functions'
import SchoolDAO from '../dao/SchoolDAO'
import Bugsnag from '@bugsnag/js'
import firebase from 'firebase/app'

async function _addPermissionsToStore(
  user: UserData,
  storeDispatcher: Dispatch,
): Promise<void> {
  const permissionDAO = new PermissionDAO()

  try {
    const querySnapshot = await permissionDAO
      .equals('profileId', user.profileId as string)
      .get()

    const permissions = querySnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() } as Permission
    })[0]

    const permissionMap = flatObjectToMap(permissions)

    storeDispatcher(permissionsFetched(permissions, permissionMap))
  } catch (e) {
    console.log(e)
  }
}

async function _addSchoolsToStore(
  user: UserData,
  storeDispatcher: Dispatch,
): Promise<void> {
  const schoolDAO = new SchoolDAO()
  try {
    const school = await schoolDAO.getById(user.schoolIds[0])

    storeDispatcher(schoolsRetrieved([school]))
  } catch (e) {
    console.log(e)
  }
}

export default function useAuthenticate(): void {
  const dispatch: Dispatch = useDispatch()
  const userDAO = new UserDAO()
  const profileDao = new ProfileDAO()

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(
      async (user: firebase.User | null) => {
        let userData: UserData | null = null
        let profile: Profile | null = null
        if (user) {
          // Set user for bugsnag report
          Bugsnag.setUser(
            user?.uid,
            user?.email as string,
            user?.displayName as string,
          )

          if (process.env.NODE_ENV !== 'production') {
            console.debug(await user.getIdToken())
          }

          userData = await userDAO.getById(user.uid)
          profile = await profileDao.getById(userData.profileId)

          await _addPermissionsToStore(userData, dispatch)

          // If user is not a "Superviseur", we set the globalSchoolId
          if (profile.name !== SUPERVISOR_PROFILE_NAME) {
            dispatch(globalSchoolSelected(userData.schoolIds[0]))

            const schoolProfiles = await profileDao.getBySchoolId(
              userData.schoolIds[0],
            )

            dispatch(schoolProfilesRetrieved(schoolProfiles))
            dispatch(fetchLevels(userData.schoolIds[0]) as any)
            // _addLevelsToStore(userData.schoolIds[0], dispatch)
            await _addSchoolsToStore(userData, dispatch)
          }
        }

        dispatch(
          authStateUpdated(
            user as User,
            userData as UserData,
            profile as Profile,
          ),
        )
      },
    )

    return unsubscribe
  })
}
