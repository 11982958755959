import React, { FC } from 'react'
import Routes from './routes/Routes'
import CSSBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import theme from './theme/theme'
import useAuthenticate from './hooks/useAuthenticate'
import { logout } from './store/modules/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import { authPersistenceSelector } from './store/selectors/auth'
import firebase from 'firebase/app'

const App: FC = () => {
  useAuthenticate()
  const dispatch = useDispatch()
  const persistence = useSelector(authPersistenceSelector)

  React.useEffect(() => {
    // Clear local user's data on tab close
    window.addEventListener('unload', () => {
      if (persistence !== firebase.auth.Auth.Persistence.LOCAL) {
        dispatch(logout())
      }
    })
  }, [dispatch, persistence])

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <CSSBaseline />
        <Routes />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
