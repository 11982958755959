import {
  ADD_SCHOOL_YEARS,
  SchoolYearActions,
  SchoolYearsState,
  SET_CURRENT_SCHOOL_YEAR,
} from './types'
import produce from 'immer'
import { SchoolYearInterface } from '../../../models/SchoolYear'

const initialState: SchoolYearsState = {
  schoolYears: [],
  selectedSchoolYear: null,
}

export default (
  state: SchoolYearsState = initialState,
  action: SchoolYearActions,
): SchoolYearsState => {
  switch (action.type) {
    case ADD_SCHOOL_YEARS:
      return produce(state, (draft) => {
        draft.schoolYears = action.payload as SchoolYearInterface[]
      })
    case SET_CURRENT_SCHOOL_YEAR:
      return produce(state, (draft) => {
        draft.selectedSchoolYear = action.payload as SchoolYearInterface
      })
    default:
      return state
  }
}
