import { Permission } from '../models/Permission'
import firebase from 'firebase'

const defaultPermissions: Permission = {
  SchoolingManagement: { read: false },
  AssessmentsManagement: {
    create: false,
    delete: false,
    read: false,
    update: false,
  },
  CommunicationManagement: {
    createCampaign: false,
    readStats: false,
  },
  TimeTableManagement: { configure: false, read: false },
  TeachersManagement: {
    create: false,
    delete: false,
    read: false,
    update: false,
  },
  profileId: '',
  CheckoutManagement: {
    closeDay: false,
    delete: false,
    read: false,
    update: false,
  },
  StudentManagement: {
    create: false,
    delete: false,
    read: false,
    update: false,
    transfer: false,
  },
  ProfileManagement: {
    create: false,
    read: false,
    update: false,
    delete: false,
  },
  UsersManagement: {
    create: false,
    read: false,
    update: false,
    delete: false,
    ban: false,
  },
  AccountManagement: {
    update: false,
  },
  SchoolYearManagement: {
    create: false,
    read: false,
    update: false,
    delete: false,
  },
  RegistrationManagement: {
    create: false,
    read: false,
    update: false,
  },
  SchoolRecordManagement: {
    create: false,
    read: false,
    delete: false,
  },
  ClassLevelManagement: {
    create: false,
    read: false,
    update: false,
    delete: false,
  },
  ClassManagement: {
    create: false,
    read: false,
    update: false,
    delete: false,
    addStudent: false,
    deleteStudent: false,
  },
  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
}

export default defaultPermissions
